import React from 'react';
import './index.less';
import { withRouter } from 'react-router-dom';
import {Spin,Button,Tabs,Icon } from 'antd';
const { TabPane } = Tabs;
const NoData = Loader.loadBaseComponent('NoData');
// const ObjectMapPersonnelCard = Loader.loadBusinessComponent('Card','ObjectMapPersonnelCard');
const ObjectMapPersonnelCard = Loader.loadBusinessComponent('ObjectMapPersonnelCard');
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", 'InfiniteScrollLayout');
const IconFont = Loader.loadBaseComponent("IconFont");
const PlaceTableTree = Loader.loadBaseComponent("PlaceTableTree");

const tabOption = [{key: '4', value: '全部出现人员',icon:'icon-S_View_VideoNumFour'},{key: '1', value: '最常出现人员',icon:'icon-L_AID_People'},{key: '2', value: '临时出现人员',icon:'icon-L_AID_Temporary'},{key: '3', value: '长期未出现人员',icon:'icon-L_AID_NotAppearing'}]
@Decorator.businessProvider("tab", "place", "user")
@withRouter
class personnelCompositionAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
      total: 0,
      limit: 100,
      offset: 0,
      infinitKey: Math.random(),
      dataList: [],
      loading: false,
      placeInfo: {},
      configData: 30,
      placeData: {},
      expandedRowKeys: []
    };
    this.infinitRef = React.createRef();
  }
  componentWillMount() {
    let { location, place, user } = this.props;
    Promise.all([
      Utils.catchPromise(Service.place.getPlaceTypeConfig({})),
      Utils.catchPromise(
        Service.organization.getPlaceIdsByUserId({
          organizationId: user.userInfo.organizationId
        })
      )
    ]).then(resustArr => {
      this.setState({
        configData: resustArr[0].data
      });
      let placeArray = place.getPlaceInfoByIds(resustArr[1].data.placeIds);
      let id = Utils.queryFormat(location.search).id;
      LM_DB.get("parameter", id).then(data => {
        this.setState(
          {
            placeInfo: data.placeInfo,
            placeData: placeArray.find(v => v.placeId === data.id) || {},
            placeTreeData: placeArray.filter(v => v.placeId === data.id) || {},
            activeKey: data.type
          },
          () => {
            this.getExpandedRowKeys(placeArray, data.id);
            this.getPersonnelList();
          }
        );
      });
    });
  }
  //搜索
  getExpandedRowKeys = (placeArray, placeId) => {
    const { place } = this.props;
    let expandedRowKeys = [];
    const id = placeArray.find(v => v.placeId === placeId).id;
    let placeIds = place.getParentPlaceListById(id).map(v => {
      if (v.placeId !== placeId) {
        return v.placeId;
      }
    });
    expandedRowKeys = placeIds;
    this.setState({
      expandedRowKeys
    });
  };
  getPersonnelList = () => {
    let {
      activeKey,
      limit,
      offset,
      dataList,
      placeData,
      placeInfo
    } = this.state;
    if (!placeData.placeId) {
      return;
    }
    this.setState({
      loading: true
    });
    Promise.all([
      Service.place.countTypeByPid(placeInfo.countTypeByPidUrl, {
        placeIds: [placeData.placeId],
        inAndOutType: activeKey
      }),
      Service.place.getTypeByPid(
        placeInfo.getTypeByPid,
        {
          limit,
          offset,
          inAndOutType: activeKey,
          placeIds: [placeData.placeId]
        },
        {
          name: placeInfo.areaName
        }
      )
    ]).then(resultArr => {
      let newData = resultArr[1].data;
      this.setState({
        loading: false,
        total: resultArr[0].data,
        dataList: dataList.concat(newData),
        offset: offset + limit
      });
    });
  };

  refresh = () => {
    this.handleTableKey(this.state.activeKey);
  };

  handleTableKey = key => {
    this.setState(
      {
        activeKey: key,
        offset: 0,
        dataList: []
      },
      () => {
        this.getPersonnelList();
      }
    );
  };

  loadMore = () => {
    this.getPersonnelList();
  };

  jumpDetail = option => {
    const { tab } = this.props;
    let moduleName;
    let id = undefined;
    let type = "";
    if (option.personId && option.hasAid) {
      id = option.personId;
      type = "ploy";
    }
    if (option.aid) {
      id = option.aid;
      type = "aid";
    }
    if (!option.aid && !option.hasAid) {
      id = option.personId;
      type = "entry";
    }
    moduleName = "objectMapPersonnelDetail";

    tab.openDetailPage({
      moduleName,
      value: id,
      data: { id },
      beforeAction: async () => {
        await window.LM_DB.add("parameter", {
          id,
          url: option.personIdInfourl,
          type
        });
      }
    });
  };
  /**设置关注 */
  onFollow = (data, event) => {
    event.stopPropagation();
    let { dataList } = this.state;
    let option = {
      personId: data.personId,
      aid: data.aid,
      isFocus: !data.isFocus
    };
    Service.person.setOrCancelFocus(option).then(res => {
      const findIndex = dataList.findIndex(
        v =>
          (v.personId && v.personId === data.personId) ||
          (v.aid && v.aid === data.aid)
      );
      if (findIndex > -1) {
        dataList[findIndex].isFocus = !dataList[findIndex].isFocus;
      }
      this.setState(
        {
          dataList
        },
        () => {
          this.infinitRef.current.forceUpdateGrid();
        }
      );
    });
  };
  /**设置关联 */
  onRelation = (data, event) => {
    event.stopPropagation();
  };
  //选择场所
  // onSelectPlace = data => {
  //   let {placeData} = this.state
  //   if (placeData.placeId === data.placeId) {
  //     return
  //   }
  //   this.setState({placeId:data.placeId,placeData:data},() => {
  //     this.getPersonnelList()
  //   })
  // }
  //展开
  // onExpand = (expanded, record) => {
  //   let {
  //     expandedRowKeys
  //   } = this.state
  //   this.setState({
  //     expandedRowKeys: expanded ? expandedRowKeys.concat(record.placeId) : expandedRowKeys.filter(v => {
  //       return v !== record.placeId
  //     })
  //   })
  // }
  render() {
    let {
      activeKey,
      total,
      dataList,
      infinitKey,
      loading,
      configData,
      placeTreeData,
      placeData,
      expandedRowKeys
    } = this.state;
    return (
      <div className="personnel-composition-analysis-view">
        <div className="personnel-composition-analysis-nav">
          <div className="personnel-composition-analysis-title">
            <div className="panel-name">人员组成分析</div>
          </div>
          <div className="personnel-composition-analysis-header">
            <div className="tab-box">
              <Tabs onChange={this.handleTableKey} activeKey={activeKey}>
                {tabOption.map(v => {
                  return (
                    <TabPane
                      disabled={loading}
                      tab={
                        <span>
                          <IconFont type={v.icon} /> {v.value}
                        </span>
                      }
                      key={v.key}
                    />
                  );
                })}
              </Tabs>
            </div>
            <div className="personnel-composition-analysis-header-right">
              <div className="personnel-total ">
                {`${
                  tabOption.find(v => {
                    return v.key === activeKey;
                  }).value
                }总数：`}
                <p className="personnel-total-num">{total}</p>
              </div>
              <Button className="refresh_btn" onClick={this.refresh}>
                <Icon type="reload" /> 刷新
              </Button>
            </div>
          </div>
        </div>
        <div className="personnel-composition-analysis-content">
          <div className="place-list-view">
            <PlaceTableTree
              data={placeTreeData}
              placeId={placeData.placeId}
              // onSelect={this.onSelectPlace}
              expandedRowKeys={expandedRowKeys}
              // onExpand={this.onExpand}
            />
          </div>
          <Spin
            spinning={loading}
            wrapperClassName="personnel-composition-analysis-list"
          >
            {dataList.length === 0 ? (
              <NoData />
            ) : (
              <div className="personnel-center">
                <InfiniteScrollLayout
                  count={total}
                  itemWidth={348}
                  itemHeight={264}
                  pdWidth={60}
                  data={dataList}
                  hasBackTop={true}
                  ref={this.infinitRef}
                  key={infinitKey}
                  loadMore={this.loadMore}
                  renderItem={(item, index) => (
                    <ObjectMapPersonnelCard
                      data={item}
                      imgUrl={item.url}
                      personId={item.personId}
                      aid={item.aid}
                      countTitle={activeKey === "3" && "距离本场所上次出现"}
                      configData={configData}
                      onClick={this.jumpDetail}
                      onFollow={this.onFollow}
                      onRelation={this.onRelation}
                      lastTime={item.recentAppearanceTime}
                      lastAddress={item.recentAppearanceAddress}
                      isFocus={item.isFocus}
                      placeLastTime={item.lastTime}
                      countDay={activeKey !== "4" && item.countDay}
                      tags={item.tags || []}
                      info={
                        <div className="info-content">
                          <div className="title">本场所最近出现：</div>
                          <p className="content-text">
                            <IconFont
                              type={"icon-S_Bar_Add"}
                              theme="outlined"
                            />
                            <span
                              className="value"
                              title={item.deviceName || "暂无"}
                            >
                              {item.deviceName || "暂无"}
                            </span>
                          </p>
                          <p className="content-text">
                            <IconFont
                              type={"icon-M_Bar_Clock"}
                              theme="outlined"
                            />
                            <span className="value">
                              {item.lastTime
                                ? Utils.formatTimeStamp(+item.lastTime)
                                : "暂无"}
                            </span>
                          </p>
                        </div>
                      }
                    />
                  )}
                />
              </div>
            )}
          </Spin>
        </div>
      </div>
    );
  }
}

export default personnelCompositionAnalysis